import {
  Configuration,
  TenantApi,
  WeappApi,
  WxApi,
  WeappTplApi,
  WxTplApi,
  DeviceApi,
  StoreApi,
  AdminApi,
  FacilitatorApi,
  HomeApi,
  DistanceApi,
  RecordApi,
  WhiteAccountApi,
  UploadApi,
} from '@/api';
import { axios } from 'juou-managebase-ui';

interface BaseApi {
  baseUrl: string;
  uploadImageUrl: string;
  tenantApi: TenantApi;
  weappApi: WeappApi;
  wxApi: WxApi;
  weappTplApi: WeappTplApi;
  wxTplApi: WxTplApi;
  deviceApi: DeviceApi;
  storeApi: StoreApi;
  adminApi: AdminApi;
  facilitatorApi: FacilitatorApi;
  homeApi: HomeApi;
  distanceApi: DistanceApi;
  recordApi: RecordApi;
  whiteAccountApi: WhiteAccountApi;
  uploadApi: UploadApi;
}

const api: BaseApi = {

} as any;

export const initApi = (basePath: string) => {
  const apiConfig = new Configuration({
    basePath,
  });
  api.baseUrl = basePath;
  api.uploadImageUrl = '/upload/image';
  api.tenantApi = new TenantApi(apiConfig, basePath, axios);
  api.weappApi = new WeappApi(apiConfig, basePath, axios);
  api.wxApi = new WxApi(apiConfig, basePath, axios);
  api.weappTplApi = new WeappTplApi(apiConfig, basePath, axios);
  api.wxTplApi = new WxTplApi(apiConfig, basePath, axios);
  api.deviceApi = new DeviceApi(apiConfig, basePath, axios);
  api.storeApi = new StoreApi(apiConfig, basePath, axios);
  api.adminApi = new AdminApi(apiConfig, basePath, axios);
  api.facilitatorApi = new FacilitatorApi(apiConfig, basePath, axios);
  api.homeApi = new HomeApi(apiConfig, basePath, axios);
  api.distanceApi = new DistanceApi(apiConfig, basePath, axios);
  api.recordApi = new RecordApi(apiConfig, basePath, axios);
  api.uploadApi = new UploadApi(apiConfig, basePath, axios);
};

export const useApi = () => api;

